const buildEnv = {
    HOSTNAME:         '81f70e6b-8b15-4b1a-bf12-e6c252964b94-p4phd',
    SERVER_PORT:      undefined,
    LICORICE_VERSION: '2.5.0-rc.2-1-ge84fe9a',
    BUILD_VERSION:    '50.e84fe9a',
    NODE_ENV:         'development',
    DEBUG_NAMES:      '',
    BUILD_TIME_LOCAL: '2025-03-07T22:21:17+00:00',
    BUILD_TIME_UTC:   '2025-03-07T22:21:17.788Z'
};

export { buildEnv };
